// dateUtils.js
import { parse, format } from 'date-fns';


// Original function using toLocaleDateString
export const formatDate = (dateString) => {
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  // Use native JavaScript Date object to format date
  return new Date(dateString).toLocaleDateString(undefined, options);
};


// New function using date-fns for strict date parsing; used in User Account- Session Date formats
/**
 * Formats a given date string into the desired display format, preserving the original date value.
 * @param {string} dateString - Date string from the database, e.g., "2024-10-01".
 * @param {string} displayFormat - The format to display the date, e.g., "MMM d, yyyy".
 * @returns {string} - Formatted date string.
 */
export const formatDateStrict = (dateString, displayFormat = 'MMM d, yyyy') => {
  // Parse the date string as a plain date without time zone shifts
  const parsedDate = parse(dateString, 'yyyy-MM-dd', new Date());
  
  // Format the date to the desired display format
  return format(parsedDate, displayFormat);
};