import React from 'react';
import { Typography, Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const MainHeading = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md')); // Down to medium (md) covers both mobiles and tablets

  return (
    <Box sx={{
      height: { xs: '50vh', md: '70vh' },
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      padding: { xs: '0 1em', sm: '0 2em' },
      overflow: 'hidden',
      backgroundImage: isSmallScreen ? 'none' : 'url(/home_page_background.png)',  // No background image on small screens
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundColor: isSmallScreen ? 'white' : 'transparent',  // White background on small screens
    }}>

      <Typography variant="h2" component="h1" sx={{
        fontWeight: 'bold',
        fontSize: { xs: '2rem', sm: '2.8rem', md: '4.7rem' },
        color: '#616161', 
        marginTop: { xs: '6em', sm: '4em', md: '0em' },
        lineHeight: { xs: '2.5rem', sm: '3rem', md: '4.7rem' },
      }}>
        Book Sessions
      </Typography>

      <Typography variant="h4" component="h2" sx={{
        fontWeight: 'regular',
        fontSize: { xs: '1.2rem', sm: '1.8rem', md: '2.5rem' },
        color: '#757575',  
        marginTop: { xs: '0.5em', md: '0.3em' },
        lineHeight: { xs: '1.5rem', sm: '2.5rem' },
      }}>
        with Top Talent at Big Tech
      </Typography>

      <Typography variant="body1" component="p" sx={{
        fontWeight: 'regular',
        fontSize: { xs: '0.9rem', sm: '1rem', md: '1.4rem' },
        color: '#9e9e9e',  
        maxWidth: '570px',
        padding: { xs: '0 1em', sm: '0 2em' },
        marginTop: '1em',
        lineHeight: { xs: '1.4rem', sm: '1.6rem', md: '2rem' },
      }}>
        {"Practice mock interviews and gain insider insights. Choose a 15, 30, or 60-minute session with experts from your target company and role."}
      </Typography>
      
    </Box>
  );
};

export default MainHeading;
