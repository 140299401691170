import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Card, Typography, Button, List, ListItem, Box, useTheme, useMediaQuery } from '@mui/material';

const SessionOption = ({ title, description, price, onBookNowClick }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box sx={{ border: 1, borderColor: 'divider', p: 1, mb: 1, borderRadius: 1 }}>
      <Typography variant="h6" sx={{ fontSize: isSmallScreen ? '1rem' : '1.1rem'}}>{title}</Typography>
      <Typography sx={{ fontSize: isSmallScreen ? '0.9rem' : '1rem' }}>
        {description}
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', m: 1 }}>
        <Typography sx={{ color: '#1890ff', fontSize: isSmallScreen ? '1rem' : '1.15rem', fontWeight: 'bold', flexGrow: 1, textAlign: 'left' }}>
          {price}
        </Typography>
        <Button 
          sx={{ 
            width: 'auto', 
            p: 1.3, 
            bgcolor: '#1890ff',
            '&:hover': { bgcolor: '#1071e5' },
            color: '#fff', 
            borderRadius: 1, 
            cursor: 'pointer', 
            textTransform: 'none', // Ensures "Book Now" is not all caps
            marginRight: 1, // Ensures some space between the button and the price
            height: { xs: '40px', sm: '42px' },  // Responsive button height
            width: { xs: '100px', sm: '120px' },
            fontSize: isSmallScreen ? '0.9rem' : '1rem',
          }} 
          onClick={() => onBookNowClick(title, price)}
        >
          Book Now
        </Button>
      </Box>
    </Box>
  );
};

const BookSessionCard = ({ product, uniqueUsername, coachId, role, company }) => {
  const navigate = useNavigate();
  // const location = useLocation();

  // const { targetRole, targetCompany } = location.state || {};
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  // Handler for when a booking option is clicked
  const handleBookingOptionClick = (productType) => {

    const queryParams = new URLSearchParams({
      productType: productType,
      product: product,
      role: role || '',
      company: company || '',
    }).toString();

    const stateToPass = { productType, product, coachId, role, company };

    navigate(`/coaches/${uniqueUsername}/booking?${queryParams}`, { state: stateToPass });
  
  };

  // Define the session features and options for each product
  const sessionContentForProducts = {
    'Ace with Mocks': {
      features: [
        "Verbal on-the-spot feedback",
        "Written feedback within hours",
        "Full refund if not satisfied",
      ],
      options: [
        {
          title: "One Hour Mock Interview",
          description: "1 hour mock interview with detailed analysis and feedback.",
          price: "$99",
        },
        {
          title: "Half Hour Mock Interview",
          description: "30 min mock interview with analysis and feedback.",
          price: "$75",
        },
        {
          title: "Short Interview Discussion",
          description: "15 min general discussion.",
          price: "$49",
        },
        // ... other sessions for Ace with Mocks
      ],
    },
    'Burnout to Break Free': {
      features: [
        "Know personal experiences",
        "Know company and State policies",
        "Full refund if not satisfied",
      ],
      options: [
        {
          title: "One Hour Discussion",
          description: "1-hour mock interview with detailed analysis and feedback.",
          price: "$99",
        },
        {
          title: "Half Hour Discussion",
          description: "30 min break free strategy and discussion.",
          price: "$75",
        },
        {
          title: "Short Discussion",
          description: "15 min general discussion.",
          price: "$49",
        },
        // ... other sessions for Burnout to Break Free
      ],
    },
    // ... other products
  };

  // Get the session content for the selected product
  const sessionContent = sessionContentForProducts[product] || { features: [], options: [] };
  const { features, options } = sessionContent;

  return (
    <Card sx={{ 
      position: 'sticky', 
      top: 2.5, 
      width: '100%', 
      p: 2.5, 
      borderRadius: 2, 
      background: 'background.paper', 
      boxShadow: 1,
      ...(isSmallScreen ? { width: '100%', top: 10 } : {})  // Adjust card position and size on small screens
    }}>
      <Typography variant="h5" sx={{ fontSize: isSmallScreen ? '1.1rem' : '1.25rem', fontWeight: 'bold', textAlign: 'center' }}>Book a session</Typography>
      <List sx={{ listStyle: 'none', p: 1, mb: 2.5 }}>
        {features.map((feature, index) => (
          <ListItem key={index} sx={{ fontSize: isSmallScreen ? '0.9rem' : '1rem' }}>{'\u2714'} {feature}</ListItem>
        ))}
      </List>
      {options.map((option, index) => (
        <SessionOption
          key={index}
          title={option.title}
          description={option.description}
          price={option.price}
          onBookNowClick={handleBookingOptionClick} // Pass the product type (e.g., session title) to the handler; 
        />
      ))}
      {/* Add other session offerings similarly */}
    </Card>
  );
};

export default BookSessionCard;
