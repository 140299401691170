// src/components/FormField.jsx

import React from 'react';
import { TextField } from '@mui/material';

const FormField = ({ type, name, onChange, value, placeholder, onFocus, onBlur, onKeyDown }) => {
  return (
    <TextField
      type={type}
      name={name}
      onChange={onChange}
      value={value}
      placeholder={placeholder}
      variant="outlined"
      fullWidth
      onFocus={onFocus} // Make sure this prop is forwarded correctly
      onBlur={onBlur} // Make sure onBlur is forwarded (if used)
      onKeyDown={onKeyDown} // Forward onKeyDown for keyboard navigation
      sx={{ m: 1, borderRadius: '4px' }}
      autoComplete="off"
    />
  );
};

export default FormField;
