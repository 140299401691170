// src/components/GenericDropdown.jsx

import React, { useState, useEffect, useRef } from 'react';
import { Box, MenuItem, ListItemText, Paper } from '@mui/material';
import FormField from './FormField';

const GenericDropdown = ({ fieldKey, fieldLabel, value, onChange, data, placeholder }) => {
  const [filteredItems, setFilteredItems] = useState(data); // Filtered items based on user input
  const [inputValue, setInputValue] = useState(value || ''); // Initialize input value from props
  const [isDropdownVisible, setDropdownVisible] = useState(false); // Control visibility of dropdown
  const [highlightedIndex, setHighlightedIndex] = useState(-1); // Track highlighted index for keyboard navigation

  // Array of refs for each dropdown item
  const itemRefs = useRef([]); // Create a mutable array of refs

  // Create ref for dropdown container to detect clicks outside
  const dropdownRef = useRef(null);

  // Populate the full list of items when the input field is focused (before typing)
  useEffect(() => {
    setFilteredItems(data); // Initialize with the complete list of items
  }, [data]);

  // Filter items based on input value whenever it changes
  useEffect(() => {
    const newFilteredItems = data.filter((item) =>
      item.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilteredItems(newFilteredItems);
  }, [inputValue, data]);

  // Handle changes in input field value
  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    onChange({ target: { name: fieldKey, value: newValue } });
    setDropdownVisible(true); // Show dropdown as soon as user types
    setHighlightedIndex(-1); // Reset highlighted index on new input
  };

  // Show dropdown on input field focus (before typing)
  const handleFocus = () => {
    setFilteredItems(data); // Show full dropdown list when field is focused
    setDropdownVisible(true); // Set dropdown to visible on focus
    setHighlightedIndex(-1); // Reset highlighted index on focus
  };

  // Handle selecting an item from the dropdown
  const handleItemClick = (item) => {
    setInputValue(item); // Update input value with selected item
    onChange({ target: { name: fieldKey, value: item } });
    setDropdownVisible(false); // Hide dropdown after selection
  };

  // Scroll to the highlighted item if it's not in view
  const scrollToHighlightedItem = () => {
    if (highlightedIndex >= 0 && itemRefs.current[highlightedIndex]) {
      itemRefs.current[highlightedIndex].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  };

  // Handle keyboard navigation for dropdown items and handle escape key
  const handleKeyDown = (event) => {
    if (event.key === 'ArrowDown') {
      // Navigate down the list
      setHighlightedIndex((prevIndex) => {
        const newIndex = Math.min(prevIndex + 1, filteredItems.length - 1);
        scrollToHighlightedItem(); // Scroll to new index if not in view
        return newIndex;
      });
      setDropdownVisible(true); // Ensure dropdown is visible when navigating with keys
    } else if (event.key === 'ArrowUp') {
      // Navigate up the list
      setHighlightedIndex((prevIndex) => {
        const newIndex = Math.max(prevIndex - 1, 0);
        scrollToHighlightedItem(); // Scroll to new index if not in view
        return newIndex;
      });
    } else if (event.key === 'Enter' && highlightedIndex >= 0) {
      // Select the highlighted item when Enter is pressed
      handleItemClick(filteredItems[highlightedIndex]);
      event.preventDefault(); // Prevent form submission on Enter key
    } else if (event.key === 'Escape') {
      // Handle Escape key press to close the dropdown
      setDropdownVisible(false); // Hide the dropdown
      setHighlightedIndex(-1); // Reset highlighted index
    }
  };

  // Detect clicks outside of the dropdown and hide it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <Box sx={{ position: 'relative', width: '100%' }} ref={dropdownRef}>
      {/* Input field component */}
      <FormField
        type="text"
        name={fieldKey}
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown} // Attach keyboard navigation and escape handling
        placeholder={placeholder} // Use the provided placeholder
        onFocus={handleFocus} // Show dropdown on focus
      />

      {/* Render dropdown only when visible and items are available */}
      {isDropdownVisible && filteredItems.length > 0 && (
        <Paper
          sx={{
            position: 'absolute',
            width: '100%',
            maxHeight: 200, // Limit the height of dropdown
            overflowY: 'auto', // Enable scrolling if too many items
            mt: 1,
            zIndex: 10,
          }}
        >
          {/* Map filtered items to MenuItem components */}
          {filteredItems.map((item, index) => (
            <MenuItem
              key={item}
              ref={(el) => (itemRefs.current[index] = el)} // Attach ref to each dropdown item
              onClick={() => handleItemClick(item)} // Handle item selection
              selected={index === highlightedIndex} // Highlight the selected item
              sx={{
                textAlign: 'left',
                bgcolor: index === highlightedIndex ? '#f5f5f5' : 'inherit',
              }}
            >
              <ListItemText primary={item} />
            </MenuItem>
          ))}
        </Paper>
      )}
    </Box>
  );
};

export default GenericDropdown;
