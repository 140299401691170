// src/pages/ContactUs.jsx

import React from 'react';
import { Box, Typography, Container } from '@mui/material';

const ContactUs = () => {
  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography
             variant="h4" // Use the same variant as your other page
             component="h1" // Use the same component type
             sx={{
                textAlign: 'center', // Center align the text
                mt: {
                xs: 15,  // Margin-top for extra-small devices (mobile)
                sm: 20,  // Slightly larger margin-top for small devices (tablets)
                md: 25,  // For medium devices
                lg: 30,  // Original margin-top for large devices (desktop)
                },
                color: '#424242', // Keep the same color as your original style
                fontSize: '1.2rem', // Optional: Font size can be adjusted as per your needs
            }}
            >
                For questions, requests, or feedback, contact us at{' '}
            <Typography component="span" sx={{ fontWeight: 'bold', display: 'inline', color: '#424242', fontSize: '1.2rem', }}>
                support@eastbadge.com
            </Typography>{' '}
                — we typically respond within hours.
            </Typography>
        </Box>
    </Container>
  );
};

export default ContactUs;
