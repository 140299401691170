	import React, { useState } from 'react';
	import { useAuth } from '../AuthContext';
	import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
	import SignOut from '../pages/SignOut';
	import eastbadge_logo from '../assets/eastbadge_logo_blue.png';
	import { AppBar, Toolbar, Typography, Button, Box, Grid, Container, Menu, MenuItem, IconButton } from '@mui/material';
	import MenuIcon from '@mui/icons-material/Menu';
	
  const Layout = ({ children }) => {
	  const { currentUser } = useAuth();
	  const location = useLocation();
	  const navigate = useNavigate();
	  const [anchorEl, setAnchorEl] = useState(null);  // State to manage the anchor element for the menu
	  const open = Boolean(anchorEl);  // Boolean state to manage the open/close of the menu
	  
    const handleMenu = (event) => {
	    setAnchorEl(event.currentTarget);  // Set the anchor element to the current target, opening the menu
	  };
	
    const handleClose = () => {
	    setAnchorEl(null);  // Reset the anchor element, closing the menu
	  };
	
    // Function to handle the direct navigation to the account
	  const goToAccount = () => {
	    navigate('/account');  // Navigate to the account page
	    handleClose();  // Close the menu
	  };
	
    // Function to navigate to Sign In page while preserving current location
	  const goToSignIn = () => {
	    navigate('/signin', { state: { from: location } }); // Pass current location as state
	  };
	
    return (
	    <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
	      <AppBar position="static" sx={{
	        backgroundColor: '#fff',
	        color: '#fff',
	        minHeight: 84,
	        boxShadow: '0px 6px 6px -2px rgba(0, 0, 0, 0.1)',
	        '@media (max-width:600px)': { minHeight: 64 }
	      }}>
	        <Container maxWidth="lg">
	          <Toolbar sx={{
	            alignItems: 'center',
	            '@media (max-width:600px)': { minHeight: '64px' },
	          }}>
	            <Typography variant="h6" sx={{
	              flexGrow: 1,
	              lineHeight: '84px',
	              fontSize: '2.2rem',
	              display: 'flex',
	              alignItems: 'center',
	              '@media (max-width:600px)': { fontSize: '1.8rem', lineHeight: '64px' }
	            }}>
	              <RouterLink to="/" style={{
	                textDecoration: 'none',
	                color: 'inherit',
	                display: 'flex',
	                alignItems: 'center',
	            }}>
	                <img
	                  src={eastbadge_logo}
	                  alt="EastBadge Logo"
	                  style={{
                      height: '55px',
	                    objectFit: 'contain',
	                    display: 'flex',
	                    alignItems: 'center',
	                    marginTop: '15px',
	                    '@media (max-width: 768px)': {  // Tablets
	                      height: '45px',              // Reduce height on tablet screens
	                    },
	                    '@media (max-width: 480px)': {  // Phones
	                      height: '35px',              // Further reduce height on phone screens
	                    }
	                  }}
	                />
	              </RouterLink>
	            </Typography>
	            <Box sx={{ display: 'flex', alignItems: 'center' }}>
	              {currentUser ? (
	                <div>
	                  <IconButton
	                    onClick={handleMenu}
	                    aria-controls="menu-appbar"
	                    aria-haspopup="true"
	                    sx={{
	                      color: '#424242',
	                      textTransform: 'none',
	                      fontSize: '1rem',
	                      mt: '14px',
	                      '@media (max-width:600px)': { fontSize: '0.9rem' }
	                    }}
	                  >
	                    <MenuIcon />
	                  </IconButton>
	                  <Menu
	                    id="menu-appbar"
	                    anchorEl={anchorEl}
	                    anchorOrigin={{
	                      vertical: 'bottom',
	                      horizontal: 'right',
	                    }}
	                    transformOrigin={{
	                      vertical: 'top',
	                      horizontal: 'right',
	                    }}
	                    open={open}
	                    onClose={handleClose}
	                  >
	                    <MenuItem onClick={goToAccount}>Account</MenuItem>
	                    <MenuItem onClick={handleClose}>
	                        <SignOut />
	                    </MenuItem>
	                  </Menu>
	                </div>
	              ) : (
	                <Button
	                  onClick={goToSignIn} // Use new function to preserve state
	                  sx={{
	                  color: '#424242',
	                  textTransform: 'none',
	                  fontSize: '1rem',
	                  mt: '14px',
	                  '@media (max-width:600px)': { fontSize: '0.9rem' },
	                  '&:hover': {
	                    backgroundColor: '#fafafa',  // Change background color on hover
	                  }
	                  }}
	                >
	                  Register/ Sign In
	                </Button>
	              )}
	            </Box>
	          </Toolbar>
	        </Container>
	      </AppBar>
	      <Container component="main" sx={{ mt: 2, mb: 2, flex: 1 }}>
	        {children}
	      </Container>
	      <Box
	        component="footer"
	        sx={{
	          backgroundColor: '#424242',
	          color: 'white',
	          p: 2,  // Reduced padding for smaller footer
	          mt: 'auto',
	          '@media (max-width:600px)': {
	          p: 1  // Even smaller padding for very small devices
	          }
	        }}
	      >
	        <Container maxWidth="lg">
	          <Grid container spacing={3} justifyContent="center">
	            <Grid item xs={12} sm={6} sx={{
	              display: 'flex',
	              flexDirection: 'column',
	              alignItems: 'center',
	              '@media (max-width:600px)': {
	              mb: 1
	            }
	          }}>
	            <Typography variant="body1" sx={{
	              mb: 1,
	              fontWeight: 'bold',
	              '@media (max-width:600px)': {
	                fontSize: '0.9rem'
	              }
	            }}>
	              Company
	            </Typography>
	            <RouterLink to="/faqs" style={{
	              textDecoration: 'none',
	              color: 'inherit',
	              marginBottom: '8px',
	              fontSize: '0.9rem',
	              '@media (max-width:600px)': {
	                fontSize: '0.8rem'
	              }
	            }}>FAQs</RouterLink>
	            <RouterLink to="/contact-us" style={{
	              textDecoration: 'none',
	              color: 'inherit',
	              marginBottom: '8px',
	              fontSize: '0.9rem',
	              '@media (max-width:600px)': {
	                fontSize: '0.8rem'
	              }
	            }}>Contact Us</RouterLink>
	            <RouterLink to="/become-an-expert" style={{
	              textDecoration: 'none',
	              color: 'inherit',
	              fontSize: '0.9rem',
	              '@media (max-width:600px)': {
	                fontSize: '0.8rem'
	              }
	            }}>Become an Expert</RouterLink>
	          </Grid>
	          <Grid item xs={12} sm={6} sx={{
	              display: 'flex',
	              flexDirection: 'column',
	              alignItems: 'center',
	              '@media (max-width:600px)': {
	                mb: 1
	              }
	            }}>
	              <Typography variant="body1" sx={{
	                mb: 1,
	                fontWeight: 'bold',
	                '@media (max-width:600px)': {
	                  fontSize: '0.9rem'
	                }
	              }}>
	                Legal
	              </Typography>
	              <RouterLink to="/terms-of-use" style={{
	                textDecoration: 'none',
	                color: 'inherit',
	                marginBottom: '8px',
	                fontSize: '0.9rem',
	                '@media (max-width:600px)': {
	                  fontSize: '0.8rem'
	                }
	              }}>Terms of Use</RouterLink>
	              <RouterLink to="/privacy-policy" style={{
	                textDecoration: 'none',
	                color: 'inherit',
	                fontSize: '0.9rem',
	                '@media (max-width:600px)': {
	                  fontSize: '0.8rem'
	                }
	              }}>Privacy Policy</RouterLink>
	            </Grid>
	            <Grid item xs={12}>
	              <Typography variant="body2" align="center" style={{
	                fontSize: '0.9rem',
	                '@media (max-width:600px)': {
	                  fontSize: '0.8rem'
	                }
	              }}>
	                © 2024 EastBadge, Inc. All rights reserved.
	              </Typography>
	            </Grid>
	          </Grid>
	        </Container>
	      </Box>
	    </Box>
	  );
	};
	export default Layout;
	
	
	
