// src/pages/FAQs.jsx

import React from 'react';
import FAQSection from '../components/FAQSection'; 
import { VALID_PRODUCTS } from '../constants'; 
import { Box } from '@mui/material';

const FAQs = () => {
  return (
    <Box sx={{ px: { xs: 2, sm: 4, md: 6, lg: 8 }, py: 4 }}>
      <FAQSection product={null} validProducts={VALID_PRODUCTS} /> {/* Passing validProducts to display FAQs */}
      </Box>
  );
};

export default FAQs;
