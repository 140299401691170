// src/components/ProductForm.jsx

import React, { useState } from 'react';
import { Button } from '@mui/material';
import GenericDropdown from './GenericDropdown';
import { PRODUCT_FIELDS } from '../constants'; // Import the product fields configuration

const ProductForm = ({ productTitle, onFormSubmit }) => {
  const [formData, setFormData] = useState({});

  // Retrieve field configuration for the selected product
  const { fields } = PRODUCT_FIELDS[productTitle];

  // Handle form field changes
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle form submission and create the correct formData based on product
  const handleSubmit = (event) => {
    event.preventDefault();
  
    let updatedFormData = {};
    if (productTitle === "Ace with Mocks") {
      // Use `targetRole` and `targetCompany` for "Ace with Mocks"
      updatedFormData = {
        targetRole: formData.targetRole || '',  // Ensure empty string if field is blank
        targetCompany: formData.targetCompany || '',  // Ensure empty string if field is blank
      };
    } else if (productTitle === "Burnout to Break Free") {
      // Use `role` and `company` for "Burnout to Break Free"
      updatedFormData = {
        role: formData.role || '',
        company: formData.company || '',
      };
    }

    // Call onFormSubmit with the correct parameters based on product type
    if (productTitle === "Ace with Mocks") {
      onFormSubmit(productTitle, updatedFormData.targetRole, updatedFormData.targetCompany);
    } else if (productTitle === "Burnout to Break Free") {
      onFormSubmit(productTitle, updatedFormData.role, updatedFormData.company);
    }
  };

  return (
    <>
      {/* Form Rendering */}
      <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
        {/* Render GenericDropdown dynamically based on product fields */}
        {fields.map(({ key, label, dataSource }) => (
          <GenericDropdown
            key={key}
            fieldKey={key}
            fieldLabel={label}
            value={formData[key] || ''}
            onChange={handleChange}
            data={dataSource}
            placeholder={label}
          />
        ))}

        {/* Submit Button */}
        <Button 
          type="submit" 
          fullWidth 
          variant="contained" 
          sx={{ 
            bgcolor: '#1890ff', 
            '&:hover': { bgcolor: '#1071e5' },
            textTransform: 'capitalize',
            height: '40px', 
            ml: '8px',
          }}>
          
          Submit
        </Button>
      </form>
    </>
  );
};

export default ProductForm;
