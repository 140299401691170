
export const VALID_PRODUCTS = [
    'Ace with Mocks',
    'Burnout to Break Free',
  ];
  
export const VALID_PRODUCT_TYPES = [
  'One Hour Mock Interview',
  'Half Hour Mock Interview',
  'Short Interview Discussion',
  'One Hour Discussion',
  'Half Hour Discussion',
  'Short Discussion',
];
  
// Roles and Companies for "Ace with Mocks" that will appear in the drop down on the Home page
export const ACE_WITH_MOCKS_ROLES = [
  'Product Manager',
  'Product Manager-Technical',
  'Senior Product Manager',
  'Senior Product Manager-Technical',
  'Senior Product Manager-Technical External Services',
  'Principal Product Manager',
  'Principal Product Manager-Technical',
  'Principal Product Manager-Technical External Services',
  'Group Product Manager',
];

export const ACE_WITH_MOCKS_COMPANIES = [
  'Amazon',
  'Amazon Web Services',
  'Netflix',
];

// Roles and Companies for "Burnout to Break Free" that will appear in the drop down on the Home page
export const BURNOUT_TO_BREAK_FREE_ROLES = [
  'Product Manager',
  'Product Manager-Technical',
  'Senior Product Manager',
  'Senior Product Manager-Technical',
  'Senior Product Manager-Technical External Services',
  'Principal Product Manager',
  'Principal Product Manager-Technical',
  'Principal Product Manager-Technical External Services',
  'Group Product Manager',
];

export const BURNOUT_TO_BREAK_FREE_COMPANIES = [
  'Amazon',
  'Amazon Web Services',
  'Netflix',
];

// Field configurations for each product
export const PRODUCT_FIELDS = {
  'Ace with Mocks': {
    fields: [
      { key: 'targetRole', label: 'Target role (optional)', dataSource: ACE_WITH_MOCKS_ROLES },
      { key: 'targetCompany', label: 'Target company (optional)', dataSource: ACE_WITH_MOCKS_COMPANIES },
    ],
  },
  'Burnout to Break Free': {
    fields: [
      { key: 'role', label: 'Role (optional)', dataSource: BURNOUT_TO_BREAK_FREE_ROLES },
      { key: 'company', label: 'Company (optional)', dataSource: BURNOUT_TO_BREAK_FREE_COMPANIES },
    ],
  },
  // 'Ivy League MBA': {
  //   fields: [
  //     { key: 'university', label: 'University', dataSource: IVY_LEAGUE_MBA_UNIVERSITIES },
  //     { key: 'program', label: 'Program', dataSource: IVY_LEAGUE_MBA_PROGRAMS },
  //   ],
  // },
};
