import React, { useState, useEffect } from 'react';
import { Typography, Accordion, AccordionSummary, AccordionDetails, Box, useTheme, useMediaQuery } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQ = ({ question, answer }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Accordion sx={{ 
      boxShadow: 'none', 
      background: 'transparent', 
      mb: 1, 
      "&:before": { display: 'none' },
      // Adjust the border radius and padding for small screens
      ...(isSmallScreen ? { borderRadius: '2px' } : { borderRadius: '4px' })
    }}>
      <AccordionSummary 
        expandIcon={<ExpandMoreIcon />} 
        sx={{ 
          backgroundColor: '#fafafa', 
          borderRadius: '4px', 
          ':hover': { backgroundColor: '#f0f0f0' },
        }}
      >
        <Typography sx={{ fontWeight: 'bold', cursor: 'pointer' }}>{question}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ backgroundColor: 'transparent', pt: 2, pb: 2.5 }}>
        <Typography>{answer}</Typography>
      </AccordionDetails>
    </Accordion>
  );
};

const FAQSection = ({ product, validProducts }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  
  const faqsForProducts = validProducts.reduce((acc, prod) => {
    if (prod === 'Ace with Mocks') {
      acc[prod] = [
        {
          question: `Can I use my session for general Q&A about the company, its culture, or other job-related topics 
          instead of a mock interview?`,
          answer: `If you’d prefer a general discussion, just let your expert know at the start of the session. 
          You can use the time to focus on topics that you believe will best prepare you for your interview.`
        },
        {
          question: "Can I purchase multiple sessions as a bundle with the same expert to track progress over time?",
          answer: (
            <>
              You can book multiple sessions individually, based on the expert's availability. We don’t offer bundles 
              yet, but if you’d like us to prioritize this feature, contact us at <strong>support@eastbadge.com</strong>.
            </>
          )
        },
        {
          question: "What if I didn’t get value from my session? Can I get a refund?",
          answer: `Yes, if you’re not satisfied with your session, you can request a refund within 12 hours of the 
          session’s end time. You’ll receive a full refund, minus any payment processing fees charged by our payment 
          provider. Neither the expert nor EastBadge is compensated when a refund is issued. We ask that you submit 
          your refund request promptly, as we need to process expert payments shortly after the session.`
        },
        {
          question: "Can I cancel my session?",
          answer: `Yes, you can cancel your session up until the scheduled start time. You'll receive a full refund, 
          minus any payment processing fees charged by our payment provider. EastBadge does not charge a cancellation fee.`
        },
        {
          question: "Can I reschedule or modify my session?",
          answer: `If you need to modify a session, we recommend canceling and rebooking it. Currently, in-app modifications
           aren’t supported, but we expect to have this feature available by H1 2024.`
        },
        {
          question: "What if my question isn’t answered here?",
          answer: (
            <>
              If you don’t see your question here, feel free to email us at <strong>support@eastbadge.com</strong>. We usually respond 
              within a few hours. 
            </>
          )
        },
      ];
    } else if (prod === 'Burnout to Break Free') {
      acc[prod] = [
        {
          question: "Can I purchase multiple sessions as a bundle with the same expert to track progress over time?",
          answer: (
            <>
              You can book multiple sessions individually, based on the expert's availability. We don’t offer bundles 
              yet, but if you’d like us to prioritize this feature, contact us at <strong>support@eastbadge.com</strong>.
            </>
          )
        },
        {
          question: "What if I didn’t get value from my session? Can I get a refund?",
          answer: `Yes, if you’re not satisfied with your session, you can request a refund within 12 hours of the 
          session’s end time. You’ll receive a full refund, minus any payment processing fees charged by our payment 
          provider. Neither the expert nor EastBadge is compensated when a refund is issued. We ask that you submit 
          your refund request promptly, as we need to process expert payments shortly after the session.`
        },
        {
          question: "Can I cancel my session?",
          answer: `Yes, you can cancel your session up until the scheduled start time. You'll receive a full refund, 
          minus any payment processing fees charged by our payment provider. EastBadge does not charge a cancellation fee.`
        },
        {
          question: "Can I reschedule or modify my session?",
          answer: `If you need to modify a session, we recommend canceling and rebooking it. Currently, in-app modifications
           aren’t supported, but we expect to have this feature available by H1 2024.`
        },
        {
          question: "What if my question isn’t answered here?",
          answer: (
            <>
              If you don’t see your question here, feel free to email us at <strong>support@eastbadge.com</strong>. We usually respond 
              within a few hours. 
            </>
          )
        },
      ];
    }
    return acc;
    // ... other products
  }, {});

  // Default FAQs if product not found
  const defaultFaqs = [
    {
      question: "Can I purchase multiple sessions as a bundle with the same expert to track progress over time?",
      answer: (
        <>
          You can book multiple sessions individually, based on the expert's availability. We don’t offer bundles 
          yet, but if you’d like us to prioritize this feature, contact us at <strong>support@eastbadge.com</strong>.
        </>
      )
    },
    {
      question: "What if I didn’t get value from my session? Can I get a refund?",
      answer: `Yes, if you’re not satisfied with your session, you can request a refund within 12 hours of the 
      session’s end time. You’ll receive a full refund, minus any payment processing fees charged by our payment 
      provider. Neither the expert nor EastBadge is compensated when a refund is issued. We ask that you submit 
      your refund request promptly, as we need to process expert payments shortly after the session.`
    },
    {
      question: "Can I cancel my session?",
      answer: `Yes, you can cancel your session up until the scheduled start time. You'll receive a full refund, 
      minus any payment processing fees charged by our payment provider. EastBadge does not charge a cancellation fee.`
    },
    {
      question: "Can I reschedule or modify my session?",
      answer: `If you need to modify a session, we recommend canceling and rebooking it. Currently, in-app modifications
       aren’t supported, but we expect to have this feature available by H1 2024.`
    },
    {
      question: "What if my question isn’t answered here?",
      answer: (
        <>
          If you don’t see your question here, feel free to email us at <strong>support@eastbadge.com</strong>. We usually respond 
          within a few hours. 
        </>
      )
    },
    // ... more default FAQs
  ];

  const faqs = product ? faqsForProducts[product] || defaultFaqs : defaultFaqs;

  return (
    <Box sx={{ mb: 2 }}>
      <Typography variant="h5" sx={{ mb: 2, mt: 4 }}>FAQs</Typography>
      {faqs.map((faq, index) => (
        <FAQ key={index} question={faq.question} answer={faq.answer} />
      ))}
    </Box>
  );
};

export default FAQSection;
