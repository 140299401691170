// UserAccount.jsx
import React, { useState, useEffect } from 'react';
import { Box, Tab, Tabs, Snackbar, Alert, useMediaQuery, useTheme } from '@mui/material';
import UserProfile from '../components/UserProfile'; 
import UserSessions from '../components/UserSessions'; 
import { useNavigate } from 'react-router-dom';

const UserAccount = () => {
  const [selectedTab, setSelectedTab] = useState(0); // For Profile and Sessions tabs
  const [successMessage, setSuccessMessage] = useState('');  
  const [errorMessage, setErrorMessage] = useState('');
  const [csrfToken, setCsrfToken] = useState('');
  const navigate = useNavigate();

  // Use theme and media query to determine screen size
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  // Function to fetch the CSRF token
  const fetchCsrfToken = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/get_csrf_token/`, {
        credentials: 'include', // Include credentials to send session cookie
      });

      if (response.ok) {
        const data = await response.json();
        setCsrfToken(data.csrfToken); // Store CSRF token in state
      } else {
        throw new Error('Failed to fetch CSRF token');
      }
    } catch (error) {
      console.error('Error fetching CSRF token:', error);
      setErrorMessage('Error fetching CSRF token.');
    }
  };

  // Fetch the CSRF token when the component mounts
  useEffect(() => {
    fetchCsrfToken();
  }, []);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box 
      sx={{ 
        display: 'flex', 
        height: '100vh',
        flexDirection: { xs: 'column', md: 'row' },
        overflowX: { xs: 'auto', md: 'hidden' }, 
      }}
    >
      {/* Left pane for navigation */}
      <Box 
        sx={{ 
          width: { xs: '100%', md: '25%' }, 
          bgcolor: '#f5f5f5', 
          color: '#747474',
          pt: 5, 
          position: 'relative', 
          top: 0, 
          display: { xs: 'flex', md: 'block' },
          justifyContent: { xs: 'center', md: 'initial' },
          overflowX: 'auto'
        }}  
      >
        <Tabs
          orientation=  {isSmallScreen ? 'horizontal' : 'vertical'}
          value={selectedTab}
          onChange={handleTabChange}
          variant={isSmallScreen ? 'scrollable' : 'standard'}
          scrollButtons={isSmallScreen ? 'auto' : false}
          sx={{ 
            pl: { md: 0, xs: 2 },  
            alignItems: { md: 'flex-start', xs: 'center' },
            textAlign: { xs: 'center', md: 'left' },
            '.MuiTabs-indicator': {
              backgroundColor: 'transparent', 
            },
          }}
        >
          <Tab 
            label="Profile" 
            sx={{ 
              textTransform: 'none', 
              fontSize: { xs: '0.9rem', md: '1rem' },
              pl: 0,
              justifyContent: { xs: 'center', md: 'flex-start' },
              width: '100%',
              '&.Mui-selected': {
                bgcolor: '#e0e0e0',  
                color: '#5c5c5c',    
                borderRight: { md: '4px solid #5c5c5c', xs: 'none' }, 
                borderRadius: 1,
              },
              '&:hover': {
                bgcolor: '#eeeeee',  
              },
            }} 
          />
          <Tab 
            label="Sessions" 
            sx={{ 
              textTransform: 'none', 
              fontSize: { xs: '0.9rem', md: '1rem' },
              justifyContent: { xs: 'center', md: 'flex-start' },
              width: '100%',
              '&.Mui-selected': {
                bgcolor: '#e0e0e0',  
                color: '#5c5c5c',    
                borderRight: { md: '4px solid #5c5c5c', xs: 'none' }, 
                borderRadius: 1,
              },
              '&:hover': {
                bgcolor: '#eeeeee',  
              },
            }} 
          />
        </Tabs>
      </Box>

      {/* Right pane for content */}
      <Box sx={{ width: { xs: '100%', md: '80%' }, p: 3, overflowX: 'auto' }}>
        {selectedTab === 0 && (
          <UserProfile 
            setSuccessMessage={setSuccessMessage} 
            setErrorMessage={setErrorMessage} 
            csrfToken={csrfToken}  
          />
        )} 
        {selectedTab === 1 && (
          <UserSessions 
            setSuccessMessage={setSuccessMessage} 
            setErrorMessage={setErrorMessage} 
            csrfToken={csrfToken}  
          />
        )}
      </Box>

      {/* Snackbar for Success Message */}
      {successMessage && (
        <Snackbar
          open={!!successMessage} // Show when successMessage is not empty
          autoHideDuration={3000}  
          onClose={() => setSuccessMessage('')} 
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} 
        >
          <Alert 
            onClose={() => setSuccessMessage('')} 
            severity="success"
            sx={{ width: '100%' }}
          >
            {successMessage}
          </Alert>
        </Snackbar>  
      )}
            
      {/* Snackbar for Error Message */}
      {errorMessage && (
        <Snackbar
          open={!!errorMessage} // Show when errorMessage is not empty
          autoHideDuration={3000} 
          onClose={() => setErrorMessage('')} 
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} 
        >
          <Alert 
            onClose={() => setErrorMessage('')} 
            severity="error" 
            sx={{ width: '100%' }}
          >
            {errorMessage}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

export default UserAccount;
