
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { formatDateStrict } from '../utils/dateUtils'; 
import { convertTimeTo12HourFormat } from '../utils/timeUtils';
import ErrorMessagePage from './ErrorMessagePage';
import {
  Container,
  Typography,
  Grid,
  Box,
  CircularProgress,
  Link
} from '@mui/material';

function useQuery() {
  const { search } = useLocation();
  return new URLSearchParams(search);
}

function BookingConfirmationPage() {
  const query = useQuery();
  const orderUuid = query.get('order');
  const [orderDetails, setOrderDetails] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const maxRetries = 12;
  let retryCount = 0;

  useEffect(() => {
    
    if (!query.has('order') || !orderUuid) {
      setError("There is an issue with the 'order' parameter: either it is missing, incorrect, or the provided value is not valid.");
      setLoading(false);
      return;
    }

    const intervalId = setInterval(() => {
      if (retryCount >= maxRetries) {
        clearInterval(intervalId);
        setError('Unable to confirm payment status. Contact support at support@eastbadge.com');
        setLoading(false);
        return;
      }
      
      // fetch(`http://localhost:8000/api/v1/order/status/?order=${orderUuid}`)
      fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/order/status/?order=${orderUuid}`)
        .then(response => Promise.all([response.ok, response.json()])) // Ensure json parsing happens even for non-200 responses
        .then(([ok, data]) => {
          if (ok && data.order_status === 'confirmed') {
            clearInterval(intervalId);
            fetchOrderDetails();
          } else if (!ok) {
            throw new Error(data.error || 'Unknown error from server. Contact support at support@eastbadge.com');
          } else {
            retryCount++;
          }
        })
        .catch(err => {
          clearInterval(intervalId);
          setError(err.message || 'Unable to retrieve payment status. Contact support at support@eastbadge.com');
          setLoading(false);
        });
    }, 1000); // Polling every 1 second
    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, [orderUuid]);
  

  function fetchOrderDetails() {
    // fetch(`http://localhost:8000/api/v1/order/confirmation?order=${orderUuid}`)
    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/order/confirmation?order=${orderUuid}`)
      .then(response => {
        if (!response.ok) {
          return response.json().then(errData => {
            // Here, we are extracting the error message from the response body if available
            throw new Error(errData.error || 'Something went wrong while fetching the data. Contact support at support@eastbadge.com');
          });
        }
        return response.json();
      })
      .then(data => {
        if (data) {
          data.formattedDate = formatDateStrict(data.date);
          data.formattedStartTime = data.start_time ? convertTimeTo12HourFormat(data.start_time) : '';
          data.formattedEndTime = data.end_time ? convertTimeTo12HourFormat(data.end_time) : '';
        }
        setOrderDetails(data);
        setError(null); // Reset any prior errors
        setLoading(false);
      })
      .catch(error => {
        // This block now correctly handles all types of errors including those thrown from the above block
        setError(error.message || "An unexpected error occurred. Contact support at support@eastbadge.com");
        setLoading(false);
      });
  }


  if (loading) {
    return (
      <Container>
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      </Container>
    );
  }


  if (error) {
    return <ErrorMessagePage message={error} />;
  }


  if (orderDetails) {
    // Function to format amount ensuring it's numeric and to two decimal places
    const formatAmount = (amount) => {
      const number = Number.parseFloat(amount);
      return isNaN(number) ? '0.00' : number.toFixed(2);
    };
    return (
        <Container sx={{ pb: 0, minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box p={2}>
              <Typography variant="h4" gutterBottom>
                Your payment is processed successfully.
              </Typography>
              <ul style={{ paddingLeft: '20px', fontSize: '1rem' }}>
                <li>
                  <Typography variant="body1" sx={{fontSize: '1rem' }}>
                    Payment confirmation and session detail emails have been sent to your email {orderDetails.user_email}.
                  </Typography>
                </li>
                <li>
                  <Typography variant="body1" sx={{fontSize: '1rem' }}>
                    A meeting invite will be sent shortly.
                  </Typography>
                </li>
              </ul>
              <Typography variant="h6" gutterBottom>
                Session Details
              </Typography>
              <ul style={{ paddingLeft: '20px', fontSize: '1rem' }}>
                <li>
                <Typography variant="body1" sx={{ fontSize: '1rem', display: 'inline' }}>
                    You have paid{' '}
                </Typography>
                <Typography variant="body1" sx={{ fontSize: '1rem', fontWeight: 'bold', display: 'inline' }}>
                    {orderDetails.currency.toUpperCase()} {formatAmount(orderDetails.amount_paid)}
                </Typography>
                <Typography variant="body1" sx={{ fontSize: '1rem', display: 'inline' }}>
                    {' '}for {orderDetails.product_type}-{orderDetails.product}.
                </Typography>
                </li>
                <li>
                  <Typography variant="body1" sx={{fontSize: '1rem' }}>
                    Your coach is {orderDetails.coach} and you will meet over a Google Meet/Zoom call.
                  </Typography>
                </li>
              </ul>    
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                padding: '0px',
                backgroundColor: '#f5f5f5',
                borderRadius: '4px 4px 0 0',
                margin: '0px',
                }}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    '@media (max-width:600px)': {
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                      },
                }}>
                    <Typography sx={{ fontSize: '1.25rem', color: '#424242', '@media (max-width:600px)': { fontSize: '1rem' }, pt: 3, pb: 1.5, pl: 2 }}>{orderDetails.formattedDate}</Typography>
                    <Typography sx={{ fontSize: '1.25rem', color: '#424242', '@media (max-width:600px)': { fontSize: '1rem' }, pt: 3, pb: 1.5 }}>{orderDetails.formattedStartTime} - {orderDetails.formattedEndTime}</Typography>
                    <Typography sx={{ fontSize: '1.25rem', color: '#424242', '@media (max-width:600px)': { fontSize: '1rem' }, pt: 3, pb: 1.5, mr: 2, pr: 2 }}>{orderDetails.user_time_zone}</Typography>
                </Box>
                <Box sx={{ paddingLeft: '10px', '@media (max-width:600px)': { paddingLeft: '0' } }}>
                    <Typography sx={{ fontSize: '0.9rem', color: '#424242', '@media (max-width:600px)': { pl: 0 }, pl: 5 }}>{orderDetails.weekday}</Typography>
                    <Typography sx={{ fontSize: '0.9rem', color: '#424242', '@media (max-width:600px)': { pl: 0 }, pl: 4, pt: 0.5, pb: 3 }}>{orderDetails.count_of_days} days to go</Typography>
                </Box>
              </Box>
              <Typography variant="h6" sx={{mt: 3 }} gutterBottom>
                Refund Policy
              </Typography>
              <ul style={{ paddingLeft: '20px', fontSize: '1rem' }}>
                <li>
                  <Typography variant="body1" sx={{fontSize: '1rem' }}>
                    You can cancel the session anytime and get a full refund.
                  </Typography>
                </li>
                <li>
                  <Typography variant="body1" sx={{fontSize: '1rem' }}>
                    You can also ask for a full refund until 12 hours after the session if you are not satisfied with the outcome.
                  </Typography>
                </li>
              </ul>
              <Typography variant="h6" sx={{mt: 3 }} gutterBottom>
                Additional
              </Typography>
              <ul style={{ paddingLeft: '20px', fontSize: '1rem' }}>
                <li>
                  <Typography variant="body1" sx={{fontSize: '1rem' }}>
                    For questions, email <Link href="mailto:support@eastbadge.com">support@eastbadge.com</Link>. You can expect a response within hours.
                  </Typography>
                </li>
                <li>
                  <Typography variant="body1" sx={{fontSize: '1rem' }}>
                    Register/Sign-Up to manage your profile and sessions.
                  </Typography>
                </li>
              </ul> 
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            {/* Placeholder for future SignIn/Registration component */}
          </Grid>
        </Grid>
      </Container>
    );
  }
  return null;
}
export default BookingConfirmationPage;
