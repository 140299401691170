import React, { useEffect } from 'react';
import { Container } from '@mui/material';

const PrivacyPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when the component mounts
      }, []);

    return (
        <Container maxWidth="md" sx={{ marginTop: 4, marginBottom: 4 }}> 
            <div className="WordSection1">
                <p className="MsoNormal" style={{ textAlign: 'center', lineHeight: 'normal' }}>
                    <b><span style={{ fontSize: '14pt', fontFamily: '"Roboto", sans-serif', color: 'black' }}>
                        EASTBADGE PRIVACY POLICY
                    </span></b>
                </p>

                <p className="MsoNormal" style={{ lineHeight: 'normal' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif' }}>&nbsp;</span>
                </p>

                <p className="MsoNormal" style={{ lineHeight: 'normal' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif', color: 'black' }}>
                        Posted date:&nbsp;
                    </span>
                    <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif' }}>October 1, 2024 </span>
                </p>

                <p className="MsoNormal" style={{ lineHeight: 'normal' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif', color: 'black' }}>
                        Effective date:&nbsp;
                    </span>
                    <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif' }}>October 1, 2024 </span>
                </p>

                <p className="MsoNormal" style={{ marginBottom: '10pt', lineHeight: 'normal' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif', color: 'black' }}>
                        EastBadge, Inc. operates the EastBadge web application. EastBadge enables users to book one-on-one online sessions with industry experts. The product currently focuses on two offerings: First, users book mock interview sessions with professionals currently working in their target roles and companies. Second, the platform enables users to engage in strategic sessions with peers who have navigated burnout in similar roles and organizations, helping them devise effective strategies to advance in their careers or grow in their current roles.
                    </span>
                </p>

                <p className="MsoNormal" style={{ marginBottom: '10pt', lineHeight: 'normal' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif', color: 'black' }}>
                        This Privacy Policy (“<b>Policy</b>”) accessed at&nbsp;
                    </span>
                    <a href="https://eastbadge.com/privacy-policy" target="_blank" style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif', color: '#467886' }}>https://eastbadge.com/privacy-policy</a>
                    <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif', color: 'black' }}>
                        &nbsp;describes how EastBadge (“<b>EASTBADGE</b>”, “<b>we</b>,” “<b>us</b>,” “<b>our</b>”) collect, use, and otherwise process the personal information we collect about our customers and users (“<b>User</b>”, “<b>Users</b>”, “<b>you</b>”, or “<b>your</b>”) of our websites, software, apps, and other services (collectively, our "<b>Services</b>"). We also collect information concerning our Experts (“<b>Experts</b>”) when they register on our website.
                    </span>
                </p>

                <p className="MsoNormal" style={{ marginBottom: '10pt', lineHeight: 'normal' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif', color: 'black' }}>
                        It is important that you carefully read and understand the terms and conditions of this Policy. By accessing our Services, you are providing your consent to our collection, use, and disclosure of information about you as described in this Policy. Additionally, our&nbsp;
                    </span>
                    <a href="/terms-of-use" style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif', color: '#467886', textDecoration: 'none' }}>
                        Terms of Use
                    </a>
                    <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif', color: 'black' }}>
                        &nbsp;are incorporated by reference into this Policy. If you do not agree to these terms and conditions of the Policy, you are not authorized to use the Services or communicate with us via the Services.
                    </span>
                </p>
                
                <p className="MsoNormal" style={{ marginBottom: '10pt', lineHeight: 'normal' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif', color: 'black' }}>
                        Please note that this Policy contains disclaimers of warranties and limitations on liability that may be applicable to you.
                    </span>
                </p>
                
                {/* Section 1 */}
                <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif', color: 'black' }}>
                        1.&nbsp;&nbsp; Personal Information. In this Policy, our use of the term "personal information" includes other similar terms under applicable privacy law such as "personal data" and "personally identifiable information". In general, personal information includes any information that identifies, relates to, describes, or is reasonably capable of being associated, or reasonably linked or linkable with a particular individual.
                    </span>
                </p>

                {/* Section 2 */}
                <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif', color: 'black' }}>
                        2.&nbsp;&nbsp; Collection and Use of Information from Users. EASTBADGE collects information that you provide us directly or automatically when you use our Services. This information may include:
                    </span>
                </p>

                <ol start="1" type="a" style={{ listStylePosition: 'inside', paddingLeft: '1.5em' }}>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Roboto", sans-serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif' }}>
                            &nbsp;&nbsp; Registration Details. Name and email address.
                        </span>
                    </li>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Roboto", sans-serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif' }}>
                            &nbsp;&nbsp; Session Management Information. Phone number, user time zone, session date and time, and payment details.
                        </span>
                    </li>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Roboto", sans-serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif' }}>
                            &nbsp;&nbsp; Session-Related Information. Targeted institutions (such as universities, companies, or other entities), desired positions or roles, and any other necessary inputs to facilitate matching users with Experts.
                        </span>
                    </li>
                </ol>

                <p className="MsoNormal" style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif', color: 'black', marginBottom: '0.5em', paddingLeft: '1.5em' }}>
                    We may use the information for purposes such as to improve the quality of our Services, personalize your experience, display relevant advertising, evaluate the success of our advertising, provide customer support, message you (in ways described by this privacy policy and managed by you in your account settings), enhance the security of our Services, fulfill transactions authorized by you, and comply with legal obligations. 
                </p>
                
                {/* Section 3 */}
                <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif', color: 'black' }}>
                        3.&nbsp;&nbsp; Collection and Use of Information from Experts. EASTBADGE collects information from our Experts when they use our Services. This information may include: 
                    </span>
                </p>

                <ol start="1" type="a" style={{ listStylePosition: 'inside', paddingLeft: '1.5em' }}>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Roboto", sans-serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif' }}>
                            &nbsp;&nbsp; Registration information to include name, email, phone number, LinkedIn profile, Bio, Past Experiences, companies worked, roles, duration of the roles, skills, certifications, programming languages and tools, language proficiency, session notice period, and calendaring information.
                        </span>
                    </li>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Roboto", sans-serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif' }}>
                            &nbsp;&nbsp; Session availability to include days of the week when expert is available for session, times of day when expert is available for session, days of the week when expert is not available for session, and time zone of the expert.
                        </span>
                    </li>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Roboto", sans-serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif' }}>
                            &nbsp;&nbsp; Payment information to include bank details or other payment method details to credit the session amount to the expert, mailing address, and any other information required to make a successful payment.
                        </span>
                    </li>
                </ol>
                
                {/* Section 4 */}
                <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif', color: 'black' }}>
                        4.&nbsp;&nbsp; Compliance with Laws. Our Privacy Policy complies with the laws on the handling of Personally Identifiable Information. Such laws include: the EU General Data Protection Regulation (GDPR), the UK Data Protection Act 2018 (DPA), the California Consumer Privacy Act (CCPA), California Privacy Rights Act (CPRA), the Virginia Consumer Data Protection Act (VCDPA), the Colorado Privacy Act (CTDPA), the Utah Consumer Privacy Act (UCPA), the Montana Consumer Data Privacy Act (MTCDPA), the Tennessee Information Protection Act (TIPA), the Oregon Consumer Privacy Act (OCPA), the Texas Data Privacy and Security Act (TDPSA), the Iowa Consumer Data Protection Act (ICDPA), the Delaware Personal Data Privacy Act (DPDPA), the Montana Consumer Data Privacy Act (MTCDPA), and the Canadian Personal Information Protection and Electronic Documents Act (PIPEDA). 
                    </span>
                </p>

                {/* Section 5 */}
                <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif', color: 'black' }}>
                        5.&nbsp;&nbsp; Legal Bases for Processing Your Data. EastBadge processes your personal information for the following legal bases:
                    </span>
                </p>

                <ol start="1" type="a" style={{ listStylePosition: 'inside', paddingLeft: '1.5em' }}>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Roboto", sans-serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif' }}>
                            &nbsp;&nbsp; Performance of our contract with you: We process your personal data to fulfill our contractual obligations to you. This includes providing you with the services you have requested, managing your account, and ensuring the proper functioning of our services.
                        </span>
                    </li>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Roboto", sans-serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif' }}>
                            &nbsp;&nbsp; To comply with a legal obligation to which EASTBADGE is subject: We may process your personal data to comply with various legal obligations, such as regulatory requirements, tax laws, and other mandatory legal provisions. This ensures that we operate within the legal framework and maintain our compliance with applicable laws and regulations.
                        </span>
                    </li>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Roboto", sans-serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif' }}>
                            &nbsp;&nbsp; For our legitimate business interests: We process your personal data to pursue our legitimate business interests, provided that such processing does not outweigh your rights and freedoms. These interests may include improving our services, conducting marketing activities, enhancing security measures, preventing fraud, and analyzing user behavior to better understand and serve our customers.
                        </span>
                    </li>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Roboto", sans-serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif' }}>
                            &nbsp;&nbsp; With your consent: In certain situations, we may process your personal data based on your explicit consent. This includes instances where you have agreed to receive marketing communications or allow us to use your data for specific purposes. You have the right to withdraw your consent at any time, which will not affect the lawfulness of processing based on consent before its withdrawal.
                        </span>
                    </li>
                </ol>

                {/* Section 6 */}
                <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif', color: 'black' }}>
                        6.&nbsp;&nbsp; Purposes of Use and Processing. While the purposes for which we may process personal information will vary depending upon the circumstances, in general, we use personal information for the business and commercial purposes set forth below:
                    </span>
                </p>

                <ol start="1" type="a" style={{ listStylePosition: 'inside', paddingLeft: '1.5em' }}>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Roboto", sans-serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif' }}>
                            &nbsp;&nbsp; Providing our Services and related support.
                        </span>
                    </li>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Roboto", sans-serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif' }}>
                            &nbsp;&nbsp; Protecting the integrity of the Services.
                        </span>
                    </li>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Roboto", sans-serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif' }}>
                            &nbsp;&nbsp; Analyzing and improving the Services and our business.
                        </span>
                    </li>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Roboto", sans-serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif' }}>
                            &nbsp;&nbsp; Personalizing the Services.
                        </span>
                    </li>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Roboto", sans-serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif' }}>
                            &nbsp;&nbsp; Advertising, marketing, and promotional purposes.
                        </span>
                    </li>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Roboto", sans-serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif' }}>
                            &nbsp;&nbsp; Securing and protecting our business.
                        </span>
                    </li>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Roboto", sans-serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif' }}>
                            &nbsp;&nbsp; Defending our legal rights.
                        </span>
                    </li>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Roboto", sans-serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif' }}>
                            &nbsp;&nbsp; Auditing, reporting, corporate governance.
                        </span>
                    </li>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Roboto", sans-serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif' }}>
                            &nbsp;&nbsp; Complying with legal obligations.
                        </span>
                    </li>
                </ol>

                {/* Section 7 */}
                <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif', color: 'black' }}>
                        7.&nbsp;&nbsp; Investigations. EASTBADGE may investigate and disclose information from or about you, as permitted by applicable law, if we have a good faith belief that such investigation or disclosure is (a) reasonably necessary to comply with legal process and law enforcement instructions and orders, such as a search warrant, subpoena, statute, judicial proceeding, or other legal process served on us; (b) helpful to prevent, investigate, or identify possible wrongdoing in connection with the Services; or (c) helpful to protect our rights, reputation, property, or that of our users, subsidiaries, or the public.
                    </span>
                </p>

                {/* Section 8 */}
                <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif', color: 'black' }}>
                        8.&nbsp;&nbsp; Vendors and Service Providers. We may engage vendors, agents, service providers, and affiliated entities to provide services to us or to Users on our behalf, such as support for the internal operations of our Services, online stores (including payment processors), products and services, as well as related offline product support services, data storage and other services. In providing their services, they may access, receive, maintain, or otherwise process personal information on our behalf. We strive to ensure that third parties handle personal information responsibly and in line with our guidelines.
                    </span>
                </p>

                {/* Section 9 */}
                <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif', color: 'black' }}>
                        9.&nbsp;&nbsp; Business Transfers. If EASTBADGE is acquired by or merged with another entity, if substantially all our assets are transferred to another company, or as part of a bankruptcy proceeding, EASTBADGE may transfer the information we have collected about you to the other company. In the event that EastBadge ceases operations and no acquisition occurs, we will notify you of your rights and securely delete all personal information.
                    </span>
                </p>

                {/* Section 10 */}
                <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal', textIndent: '-2em', paddingLeft: '1.5em' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif', color: 'black' }}>
                        10.&nbsp;&nbsp; Log Data. Whenever you use our Service, EASTBADGE collects information that your browser sends to us that is called Log Data. This Log Data may include information such as your computer’s Internet Protocol (“<b>IP</b>”) address, browser version, pages of our Services that you visit, the time and date of your visit, the time spent on those pages, and other statistics. 
                    </span>
                </p>
                
                {/* Section 11 */}
                <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal', textIndent: '-2em', paddingLeft: '1.5em' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif', color: 'black' }}>
                        11.&nbsp;&nbsp; Cookies and Other Tracking Technologies. EASTBADGE, and third parties we interact with use cookies, web beacons, local shared objects (sometimes called flash cookies), and similar technology in connection with your use of our Services and third-party websites (collectively referred to in this policy as “<b>Cookies</b>”). Cookies are small data files that may have unique identifiers, and reside, among other places, on your computer or mobile device, in emails we send to you, and on our Service. We use only essential cookies on our website. Essential cookies are necessary for the website to function properly and cannot be switched off in our systems. They are usually set in response to actions made by you, such as setting your privacy preferences, logging in, or filling out forms. These cookies do not store any personally identifiable information. Since we only use essential cookies, there is no option to disable them without affecting the functionality of our website. If you wish to manage or delete cookies, you can do so through your browser settings. However, please note that disabling essential cookies may impact your ability to use certain features of our website.
                    </span>
                </p>

                {/* Section 12 */}
                <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal', textIndent: '-2em', paddingLeft: '1.5em' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif', color: 'black' }}>
                        12.&nbsp;&nbsp; Information Security and Accuracy. EASTBADGE intends to protect your personal information and to maintain its accuracy. We implement reasonable physical, administrative, and technical safeguards to help us protect your personal information from unauthorized access, use and disclosure. For example, we use encryption to protect sensitive data during transmission over the Internet. We also require that our suppliers protect such information from unauthorized access, use and disclosure.
                    </span>
                </p>

                {/* Section 13 */}
                <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal', textIndent: '-2em', paddingLeft: '1.5em' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif', color: 'black' }}>
                        13.&nbsp;&nbsp; Retention Period. EASTBADGE will not retain personal information longer than necessary to fulfill the purposes for which it is processed, including the security of our processing, complying with legal and regulatory obligations (e.g. audit, accounting and statutory retention terms), handling disputes, and for the establishment, exercise or defense of legal claims in the countries where we do business.  If you wish to cancel your account or request that we no longer use your personal information to provide the Services to you, please contact us as set forth below, in Section 17, Contact Details. However, if you withdraw consent or otherwise object to our collection, use and disclosure of your personal information, you may not be able to use the Services. Further, to the extent permitted by applicable law, we will retain and use your personal information as necessary to comply with our legal obligations, resolve disputes, maintain appropriate business records, and enforce our agreements.
                    </span>
                </p>

                {/* Section 14 */}
                <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal', textIndent: '-2em', paddingLeft: '1.5em' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif', color: 'black' }}>
                        14.&nbsp;&nbsp; Links to Other Sites. Our Services contain links to other sites that are not controlled by EASTBADGE. Please be aware that we are not responsible for the privacy practices of such other sites. We encourage you to be aware when you leave our Services and read the privacy policies of each site you visit. This privacy policy applies only to information collected by our Services.
                    </span>
                </p>

                {/* Section 15 */}
                <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal', textIndent: '-2em', paddingLeft: '1.5em' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif', color: 'black' }}>
                        15.&nbsp;&nbsp; Notice. By use of our Service, you consent to receive electronic communications from EASTBADGE. You also agree that any such communications satisfy any legal requirement to make such communications in writing under this Policy or under any applicable laws or regulations. Specifically, we may provide notice to you by sending an email to the address that you provided as part of your registration for our Services. Any notice to EASTBADGE will be provided by both (a) send via an email to <a href="mailto:privacy@eastbadge.com" style={{ color: 'black' }}>privacy@eastbadge.com</a>; and (b) providing a copy by certified mail, return receipt requested to:  EASTBADGE, INC., 166 Geary Str STE 1500 #1458, San Francisco, CA 94108, ATTN: Legal.
                    </span>
                </p>

                {/* Section 16 */}
                <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal', textIndent: '-2em', paddingLeft: '1.5em' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif', color: 'black' }}>
                        16.&nbsp;&nbsp; Governing Law & Venue. This Agreement shall be governed in all respects by the laws of the United States of America and by the laws of the State of Texas. Harris County in the state of Texas shall be the exclusive forum for any mediation, arbitration, litigation, or dispute resolution. 
                    </span>
                </p>

                {/* Section 17 */}
                <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal', textIndent: '-2em', paddingLeft: '1.5em' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif', color: 'black' }}>
                        17.&nbsp;&nbsp; Contact Details. If you have any questions, complaints or comments regarding our Policy or practices, please submit a request by emailing <a href="mailto:privacy@eastbadge.com" style={{ color: 'black' }}>privacy@eastbadge.com</a>  or by postal mail at  EASTBADGE, INC., 166 Geary Str STE 1500 #1458, San Francisco, CA 94108, Attention: Privacy.
                    </span>
                </p>

                {/* Section 18 */}
                <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal', textIndent: '-2em', paddingLeft: '1.5em' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif', color: 'black' }}>
                        18.&nbsp;&nbsp; Information Provided Through Sessions. Users and Experts bear full responsibility for the information they elect to disclose during sessions. Any information shared during a session, whether by a User or an Expert, shall not be deemed confidential. We do not and cannot guarantee that the information disclosed during a session will remain private or that it will not be shared with third parties. Consequently, Users are advised to exercise caution and discretion when sharing sensitive or personal information during sessions. By participating in a session, Users acknowledge and accept the risk that their information may be disclosed to others.
                    </span>
                </p>

                {/* Section 19 */}
                <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal', textIndent: '-2em', paddingLeft: '1.5em' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif', color: 'black' }}>
                        19.&nbsp;&nbsp; Right to Change Terms. We reserve the right at any time, with or without cause, to (a) change the terms and conditions of this Policy; (b) change our Services, including eliminating or discontinuing any feature of our Service; or (c) deny or terminate your use of and/or access to our Service. Any changes we make will be effective immediately upon our making such changes available to our Service, with or without additional notice to you. You agree that your continued use of our Services after such changes constitutes your acceptance of such changes. You hereby acknowledge that you have carefully read all the terms and conditions of our Privacy Policy (which can be accessed at <a href="https://eastbadge.com/privacy-policy" style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif', color: '#467886', textDecoration: 'none' }}> https://eastbadge.com/privacy-policy</a> and agree to all such terms and conditions. Be sure to return to this page periodically to ensure familiarity with the most current version of this Agreement. If we make a material change to our Privacy Statement, we will post a notice at the top of this page for 30 days. BY CONTINUING TO USE OUR SERVICES AFTER SUCH REVISION TAKES EFFECT, WE CONSIDER THAT YOU HAVE READ, UNDERSTOOD THE CHANGES AND AGREE TO BE BOUND BY THE MODIFIED PRIVACY POLICY.
                    </span>
                </p>
                
                {/* Section 20 */}
                <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal', textIndent: '-2em', paddingLeft: '1.5em' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif', color: 'black' }}>
                        20.&nbsp;&nbsp; California Residents. This section applies only to California consumers. It describes how we collect, use, and share California consumers' Personal consumers' Personal Information in our role as a business, and the rights applicable to such residents. If you are unable to access this Privacy Policy due to a disability or any physical or mental impairment, please contact us and we will arrange to supply you with the information you need in an alternative format that you can access. For purposes of this section "Personal Information" has the meaning given in the California Consumer Privacy Act (“CCPA”). We might collect the following statutory categories of Personal Information:
                    </span>
                </p>

                <ol start="1" type="a" style={{ listStylePosition: 'inside', paddingLeft: '1.5em' }}>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Roboto", sans-serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif' }}>
                            &nbsp;&nbsp; Personal Information: Your personal information includes all the data you provide us with when you sign up for an account or use our services, including your name, email address, telephone number. If you make a payment for services, we do not hold your card information, it is collected by our third-party payment processors, who specialize in the secure online capture and processing of card transactions.
                        </span>
                    </li>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Roboto", sans-serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif' }}>
                            &nbsp;&nbsp; Geolocation data, such as IP address. We collect this information from your device.
                        </span>
                    </li>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Roboto", sans-serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif' }}>
                            &nbsp;&nbsp; Inferences.
                        </span>
                    </li>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Roboto", sans-serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif' }}>
                            &nbsp;&nbsp; Other personal information, for instance when you interact with us online, by phone or mail in the context of receiving help through our help desks or other support channels; participation in customer surveys or contests; or in providing the Service.
                        </span>
                    </li>
                </ol>

                <p className="MsoNormal" style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif', color: 'black', marginBottom: '0.5em', paddingLeft: '1.5em' }}>
                    As a California Resident, you have certain rights regarding the Personal Information we collect or maintain about you. Please note these rights are not absolute, and there may be cases when we decline your request as permitted by law.
                </p>
                
                <ol start="1" type="a" style={{ listStylePosition: 'inside', paddingLeft: '1.5em' }}>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Roboto", sans-serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif' }}>
                            &nbsp;&nbsp; The right of access means that you have the right to request that we disclose what Personal Information we have collected, used      and disclosed about you in the past 12 months.
                        </span>
                    </li>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Roboto", sans-serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif' }}>
                            &nbsp;&nbsp; The right of deletion means that you have the right to request that we delete Personal Information collected or maintained by us, subject to certain exceptions.
                        </span>
                    </li>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Roboto", sans-serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif' }}>
                            &nbsp;&nbsp; The right to non-discrimination means that you will not receive any discriminatory treatment when you exercise one of your privacy rights.
                        </span>
                    </li>
                </ol>

                <p className="MsoNormal" style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif', color: 'black', marginBottom: '0.5em', paddingLeft: '1.5em' }}>
                    As a California Resident, you can exercise your rights yourself or you can alternatively designate an authorized agent to exercise these rights on your behalf. Please note that to protect your Personal Information, we will verify your identity by a method appropriate to the type of request you are making. We may also request that your authorized agent have written permission from you to make requests on your behalf, and we may also need to verify your authorized agent's identity to protect your Personal Information. Please use the contact details above if you would like to:
                </p>
                
                <ol start="1" type="a" style={{ listStylePosition: 'inside', paddingLeft: '1.5em' }}>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Roboto", sans-serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif' }}>
                            &nbsp;&nbsp; Access this policy in an alternative format,
                        </span>
                    </li>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Roboto", sans-serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif' }}>
                            &nbsp;&nbsp; Exercise your rights,
                        </span>
                    </li>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Roboto", sans-serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif' }}>
                            &nbsp;&nbsp; Learn more about your rights or our privacy practices, or
                        </span>
                    </li>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Roboto", sans-serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif' }}>
                            &nbsp;&nbsp; Designate an authorized agent to make a request on your behalf.
                        </span>
                    </li>
                </ol>

                {/* Section 21 */}
                <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal', textIndent: '-2em', paddingLeft: '1.5em' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif', color: 'black' }}>
                        21.&nbsp;&nbsp; European Union, UK, and similar jurisdictions. Subject to the conditions set out in the applicable law, Users in the European Union/European Economic Area, and the United Kingdom (as well as in other jurisdictions where similar rights apply) have the following rights regards our processing of their personal information:
                    </span>
                </p>

                <ol start="1" type="a" style={{ listStylePosition: 'inside', paddingLeft: '1.5em' }}>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Roboto", sans-serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif' }}>
                            &nbsp;&nbsp; Right of access: If you ask us, we will confirm whether we are processing your personal information and, if necessary, provide you with a copy of that personal information (along with certain other details).
                        </span>
                    </li>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Roboto", sans-serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif' }}>
                            &nbsp;&nbsp; Right to correction (rectification): If the personal information we hold about you is inaccurate or incomplete, you are entitled to request to have it corrected. If you are entitled to have information corrected and if we have shared your personal information with others, we will let them know about the rectification where possible.
                        </span>
                    </li>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Roboto", sans-serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif' }}>
                            &nbsp;&nbsp; Right to erasure: You can ask us to delete your personal information in some circumstances, such as where we no longer need it or if you withdraw your consent (where applicable). If you request that we delete your personal information, we may do so by deleting your account(s) with us.
                        </span>
                    </li>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Roboto", sans-serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif' }}>
                            &nbsp;&nbsp; Right to restrict (block) processing: You can ask us to restrict the processing of your personal information in certain circumstances, such as where you contest the accuracy of that personal information, or you object to our use or stated legal basis.
                        </span>
                    </li>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Roboto", sans-serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif' }}>
                            &nbsp;&nbsp; Right to data portability: You have the right, in certain circumstances, to receive a copy of personal information we have obtained from you in a structured, commonly used, and machine-readable format, and to reuse it elsewhere or to ask us to transfer this to a third party of your choice.
                        </span>
                    </li>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Roboto", sans-serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif' }}>
                            &nbsp;&nbsp; Right to object: Where our processing is based on our legitimate interests, we must stop such processing unless we have compelling legitimate grounds that override your interest or where we need to process it for the establishment, exercise, or defense of legal claims. Where we are relying on our legitimate interests, we believe that we have a compelling interest in such processing, but we will individually review each request and related circumstances.
                        </span>
                    </li>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Roboto", sans-serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif' }}>
                            &nbsp;&nbsp; Right to object to marketing: You can ask us to stop processing your personal information to the extent we do so based on our legitimate interests for marketing purposes. If you do so, we will stop such processing for our marketing purposes.
                        </span>
                    </li>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Roboto", sans-serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif' }}>
                            &nbsp;&nbsp; Right not to be subject to automated decision-making: You have the right not to be subject to a decision when it is based on automatic processing if it produces a legal effect or similarly significantly affects you unless it is necessary for entering into or performing a contract between us. EASTBADGE does not engage in automated decision-making.
                        </span>
                    </li>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Roboto", sans-serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif' }}>
                            &nbsp;&nbsp; Right to withdraw your consent: In the event your personal information is processed based on your consent, you have the right to withdraw consent at any time, without affecting the lawfulness of processing based on consent before its withdrawal.
                        </span>
                    </li>
                    <li className="MsoNormal" style={{ color: 'black', lineHeight: 'normal', marginBottom: '0.5em', fontSize: '12pt', fontFamily: '"Roboto", sans-serif', textIndent: '-1.5em', paddingLeft: '1.5em' }}>
                        <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif' }}>
                            &nbsp;&nbsp; Right to lodge a complaint: You also have the right to lodge a complaint with a supervisory authority if you consider that our processing of your personal information infringes the law.
                        </span>
                    </li>
                </ol>

                <p className="MsoNormal" style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif', color: 'black', marginBottom: '0.5em', paddingLeft: '1.5em' }}>
                    Please note that some of these rights may be limited, such as where we have an overriding interest or legal obligation to continue to process the data. Please contact us using the information set out above, in Section 17. Contact Details, if you wish to exercise any of your rights or if you have any enquiries or complaints regarding the processing of your personal information by us.
                </p>

                {/* Section 22 */}
                <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal', textIndent: '-2em', paddingLeft: '1.5em' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif', color: 'black' }}>
                        22.&nbsp;&nbsp; Enforceability. If any provision of this Policy is found to be unlawful, void, or for any reason unenforceable, then that provision shall be deemed severable from this Policy and shall not affect the validity and enforceability of any remaining provisions.
                    </span>
                </p>
                
                {/* Section 23 */}
                <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal', textIndent: '-2em', paddingLeft: '1.5em' }}>
                    <span style={{ fontSize: '12pt', fontFamily: '"Roboto", sans-serif', color: 'black' }}>
                        23.&nbsp;&nbsp; Entire Agreement. This Policy, along with the Terms of Use, constitute the entire terms with respect to the relationship between EASTBADGE and you and supersedes all prior agreements, whether written or oral, concerning such relationship. This Policy may not be changed, waived, or modified except by EASTBADGE as provided herein or otherwise by written instrument signed by EASTBADGE.
                    </span>
                </p>

            </div>
        </Container>

    );
};

export default PrivacyPolicy;
