// UserProfile.jsx
import React, { useState, useEffect, useRef } from 'react';
import { Box, TextField, Typography, Button, IconButton, Tooltip, Alert, CircularProgress } from '@mui/material';
import { FiEdit2, FiCheck } from 'react-icons/fi';
import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const UserProfile = ({ setSuccessMessage, setErrorMessage, csrfToken }) => {
  const [isEditingName, setIsEditingName] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(true);
  const [showDeleteMessage, setShowDeleteMessage] = useState(false);

  // Fetch user email from backend (profile data)
  const fetchUserInfo = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/user/details/`, {
        method: 'GET',
        credentials: 'include',  // Include credentials to send cookies
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrfToken, // Include CSRF token in the headers
          }
      });
      if (response.ok) {
        const data = await response.json();
        setEmail(data.email);
        setFirstName(data.first_name || '');
        setLastName(data.last_name || '');  
      } else {
        console.error('Failed to fetch user info:', await response.json());
      }
    } catch (error) {
      console.error('Error fetching user info:', error);
    } finally {
      setLoading(false); // Stop loading after fetching data
    }
  };

  useEffect(() => {
    fetchUserInfo();
  }, []);

  
  useEffect(() => {
    if (showDeleteMessage) {
      // Auto-dismiss the message after 5 seconds
      const timer = setTimeout(() => {
        setShowDeleteMessage(false);
      }, 5000);
      
      return () => clearTimeout(timer); // Clear timer if component unmounts
    }
  }, [showDeleteMessage]);


  const makeFieldEditable = () => {
    setIsEditingName(true);
  };

  const cancelName = () => {
    setIsEditingName(false);
    fetchUserInfo(); // Reset name fields
  };

  const handleUpdate = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/user/update/`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken, 
        },
        credentials: 'include',
        body: JSON.stringify({
          first_name: firstName,
          last_name: lastName,
        }),
      });
      if (response.ok) {
        setSuccessMessage('Update successful.');
        setIsEditingName(false);
        setTimeout(() => setSuccessMessage(''), 3000); // Clear the message after 3 seconds
      } else {
        const errorData = await response.json();
        setErrorMessage(errorData.error || 'Update failed.');
        setTimeout(() => setErrorMessage(''), 3000); // Clear the message after 3 seconds
      }
    } catch (error) {
      setErrorMessage('An unexpected error occurred.');
      setTimeout(() => setErrorMessage(''), 3000); // Clear the message after 3 seconds
      console.error('Error updating name:', error);
    }
  };

  const handleDeleteAccount = () => {
    setShowDeleteMessage(true);
  };

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    // Profile Tab Content
    <Box>
      {/* Container for Name and Email */}
      <Box 
        sx={{ 
          border: '1px solid #f5f5f5',   
          borderRadius: 1,               
          pl: 10,
          pr: 10,
          pt: 5,
          pb: 5,                    
          width: '80%',
          marginTop: -3,
          marginLeft: -2,                   
          display: 'flex', 
          flexDirection: 'column',
          gap: 2,                       
          mb: 6,
          overflowX: { xs: 'auto', md: 'hidden' },                         
        }}
      >
        <Box sx={{ minWidth: { xs: '600px', md: '100%' } }}>
        {/* Name Section */}
        <Box 
          sx={{ 
            display: 'flex', 
            alignItems: 'center',
            ml: -2,
            justifyContent: 'flex-start'
          }}
        >
          <Typography 
            sx={{ 
              width: '30%', 
            }}
          >
            Name:
          </Typography>
          <TextField
            fullWidth
            disabled={!isEditingName}
            placeholder="First name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            sx={{ 
              ml: -15,
              mr: 1, 
              width: '37%' 
            }}
          />
          <TextField
            fullWidth
            disabled={!isEditingName}
            placeholder="Last name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            sx={{ 
              mr: 1, 
              width: '37%' 
            }}
          />
          {!isEditingName ? (
            <IconButton
              onClick={makeFieldEditable}
              sx={{
              color: 'grey',
              backgroundColor: '#f5f5f5',
              '&:hover': { bgcolor: '#e0e0e0' },
              borderRadius: '50%',
              ml: 1,
              }}
            >
              <FiEdit2 />
            </IconButton>
          ) : (
            <Box 
              sx={{ 
                display: 'flex', 
                gap: 4, 
                ml: 0 
              }}>
              <IconButton
                onClick={cancelName}
                sx={{
                  color: 'grey',
                  backgroundColor: '#f5f5f5',
                  '&:hover': { bgcolor: '#e0e0e0' },
                  borderRadius: '50%',
                  ml: 1,
                  mr: -2
                }}
              >
                <ClearIcon />
              </IconButton>
              <IconButton
                onClick={handleUpdate}
                sx={{
                  color: '#fff',
                  backgroundColor: '#1890ff',
                  borderRadius: '50%',
                  '&:hover': { bgcolor: '#1071e5' },
                  ml: 0,
                  mr: -4
                }}
              >
                <FiCheck />
              </IconButton>
            </Box>
          )}
        </Box>

        {/* Email Section */}
        <Box 
          sx={{ 
            display: 'flex', 
            alignItems: 'center',
            ml: -2, 
            pt: 2,
          }}
        >
          <Typography 
            sx={{ 
              width: '30%' 
            }}
          >
            Email:
          </Typography>
          <TextField
            fullWidth
            disabled
            placeholder="Email"
            value={email || ''} 
            sx={{ 
              ml: -15,
              mr: 1, 
              width: '75%' 
            }}
          />
          <Tooltip 
            title={
              <>
                <div>To update your email, please contact support@eastbadge.com.</div>
              </>
            }
            arrow
          >
            <Box sx={{ display: 'flex', ml: 1 }}>
              <IconButton>
                <InfoOutlinedIcon 
                  sx={{ 
                    color: '#bdbdbd',
                    '&:hover': { borderRadius: '100%' },
                  }} 
                />
              </IconButton>
            </Box>
          </Tooltip>
        </Box>
        </Box>
      </Box>

      {/* Delete Account Button */}
      <Button
        variant="outlined"
        color="error"
        onClick={handleDeleteAccount}
        sx={{ 
          mt: 0,
          ml: -2,
          textTransform: 'capitalize', 
        }}
      >
        Delete Account
      </Button>
      {/* Inline Message for Account Deletion */}
      {showDeleteMessage && (
        <Box 
          sx={{ 
            mt: 2,
            ml: -2,
            width: '100%',
          }}
        >
          <Alert 
            severity="info"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => setShowDeleteMessage(false)}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            Please contact support@eastbadge.com for this action. 
          </Alert>
        </Box>
      )}
    </Box>
  );
};

export default UserProfile;
