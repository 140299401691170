import React from 'react';
import { Modal, Box, Typography, Button, ToggleButton, ToggleButtonGroup } from '@mui/material';
import PropTypes from 'prop-types';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';

const UserSessionFeedbackView = ({ open, onClose, session }) => {
  if (!session) return null;

  // Extract feedback-related details from the session object
  const {
    rating,
    feedback,
    expert_on_time,
    technical_issues,
    technical_issue_details,
    overall_experience_rating,
    east_badge_feedback,
  } = session;

  // Calculate the appropriate width of the modal based on content length
  const totalTextLength = (feedback?.length || 0) + 
                          (technical_issue_details?.length || 0) + 
                          (east_badge_feedback?.length || 0);
  const modalWidth = totalTextLength > 100 ? 700 : 500;

  // Helper function to render stars based on rating
  const renderStars = (currentRating) => {
    const totalStars = 5;
    return (
      <Box display="flex">
        {Array.from({ length: totalStars }).map((_, index) => (
          index < currentRating ? (
            <StarIcon key={index} style={{ color: '#FFD700' }} /> // Filled star
          ) : (
            <StarBorderIcon key={index} style={{ color: '#FFD700' }} /> // Empty star
          )
        ))}
      </Box>
    );
  };

  // Helper function to render Yes/No using a toggle button group
  const renderYesNoToggle = (value) => (
    <ToggleButtonGroup
      exclusive
      value={value ? 'yes' : 'no'}
      sx={{
        border: 'none',
        boxShadow: 'none',
      }}
    >
      {/* Yes Button */}
      <ToggleButton
        value="yes"
        sx={{
          textTransform: 'capitalize',
          border: 'none',
          borderRadius: '20px',
          px: 1,
          py: 0.5,
          backgroundColor: value === true ? '#1976d2' : 'transparent',
          color: value === true ? 'white' : 'gray',
          '&:hover': {
            backgroundColor: value === true ? '#115293' : 'rgba(33, 150, 243, 0.1)',
          },
        }}
      >
        Yes
      </ToggleButton>

      {/* No Button */}
      <ToggleButton
        value="no"
        sx={{
          textTransform: 'capitalize',
          border: 'none',
          borderRadius: '20px',
          px: 1,
          py: 0.5,
          backgroundColor: value === false ? '#1976d2' : 'transparent',
          color: value === false ? 'white' : 'gray',
          '&:hover': {
            backgroundColor: value === false ? '#115293' : 'rgba(33, 150, 243, 0.1)',
          },
        }}
      >
        No
      </ToggleButton>
    </ToggleButtonGroup>
  );

  // Define common styles for labels (keys)
  const labelStyles = {
    color: '#757575', // Use a shade of gray for the label
    minWidth: '180px', // Ensure labels end at the same x-coordinate
    textAlign: 'right',
    marginRight: '16px', // Spacing between label and value
    fontSize: '0.875rem', // Reduced font size for labels
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          width: modalWidth,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 2,
          borderRadius: 2,
          display: 'flex',
          flexDirection: 'column',
          // gap: 3,
          mx: 'auto', // Center horizontally
          my: 4,      // Center vertically
          maxHeight: '80vh',
          overflowY: 'auto',
        }}
      >
        {/* Inner container to handle padding and spacing */}
        <Box sx={{ p: 6, display: 'flex', flexDirection: 'column', gap: 3 }}>
            {/* Modal Heading */}
            <Typography variant="h6" component="h2" gutterBottom>
            Your Feedback for This Session
            </Typography>

            {/* Render Star Ratings */}
            {rating && (
            <Box display="flex" alignItems="center">
                <Typography variant="body1" sx={labelStyles}>Session With Expert:</Typography>
                {renderStars(rating)}
            </Box>
            )}

            {overall_experience_rating && (
            <Box display="flex" alignItems="center">
                <Typography variant="body1" sx={labelStyles}>Overall Experience Rating:</Typography>
                {renderStars(overall_experience_rating)}
            </Box>
            )}

            {/* Yes/No Toggle Buttons for Expert On Time */}
            {typeof expert_on_time === 'boolean' && (
            <Box display="flex" alignItems="center">
                <Typography variant="body1" sx={labelStyles}>Expert On Time:</Typography>
                {renderYesNoToggle(expert_on_time)}
            </Box>
            )}

            {/* Yes/No Toggle Buttons for Technical Issues */}
            {typeof technical_issues === 'boolean' && (
            <Box display="flex" alignItems="center">
                <Typography variant="body1" sx={labelStyles}>Technical Issues:</Typography>
                {renderYesNoToggle(technical_issues)}
            </Box>
            )}

            {/* Display each feedback field only if data is present */}
            {feedback && (
            <Box display="flex" alignItems="center">
                <Typography variant="body1" sx={labelStyles}>Review of Expert:</Typography>
                <Box
                sx={{
                    border: 0,
                    borderRadius: 2,
                    borderColor: '#bdbdbd',
                    bgcolor: '#f5f5f5',
                    p: 1,
                    flex: 1, // Take up remaining space
                }}
                >
                <Typography variant="body2">{feedback}</Typography>
                </Box>
            </Box>
            )}

            {/* Display EastBadge Feedback if present */}
            {east_badge_feedback && (
            <Box display="flex" alignItems="center">
                <Typography variant="body1" sx={labelStyles}>Feedback For Us:</Typography>
                <Box
                sx={{
                    border: 0,
                    borderRadius: 2,
                    borderColor: '#bdbdbd',
                    bgcolor: '#f5f5f5',
                    p: 1,
                    flex: 1,
                }}
                >
                <Typography variant="body2">{east_badge_feedback}</Typography>
                </Box>
            </Box>
            )}

            {/* Display Technical Issue Details if present */}
            {technical_issues && technical_issue_details && (
            <Box display="flex" alignItems="center">
                <Typography variant="body1" sx={labelStyles}>Technical Issue Details:</Typography>
                <Box
                sx={{
                    border: 0,
                    borderRadius: 2,
                    borderColor: '#bdbdbd',
                    bgcolor: '#f5f5f5',
                    p: 1,
                    flex: 1,
                }}
                >
                <Typography variant="body2">{technical_issue_details}</Typography>
                </Box>
            </Box>
            )}

            {/* Close button */}
            <Button onClick={onClose} variant="contained" sx={{ mt: 2 }}>
            Close
            </Button>
        </Box>
      </Box>
    </Modal>
  );
};

UserSessionFeedbackView.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  session: PropTypes.object,
};

export default UserSessionFeedbackView;
