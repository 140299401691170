// src/components/UserSessionFeedbackSubmission.jsx
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Modal,
  Typography,
  Button,
  TextField,
  Rating,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  CircularProgress,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const CHARACTER_LIMIT = 750;

const UserSessionFeedbackSubmission = ({ open, onClose, session, onSubmit, csrfToken }) => {
  const [sessionRating, setSessionRating] = useState(0);
  const [overallExperienceRating, setOverallExperienceRating] = useState(0);
  const [feedback, setFeedback] = useState('');
  const [expertOnTime, setExpertOnTime] = useState('');
  const [technicalIssues, setTechnicalIssues] = useState('');
  const [technicalIssueDetails, setTechnicalIssueDetails] = useState('');
  const [eastBadgeFeedback, setEastBadgeFeedback] = useState(''); // New optional feedback field
  const [submitting, setSubmitting] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  // Check if the submit button should be enabled
  useEffect(() => {
    const isFormValid =
      sessionRating > 0 &&
      overallExperienceRating > 0 &&
      expertOnTime !== '' &&
      technicalIssues !== '';
    setIsSubmitDisabled(!isFormValid);
  }, [sessionRating, overallExperienceRating, expertOnTime, technicalIssues]);

  // Handle change for Feedback field with character limit
  const handleFeedbackChange = (e) => {
    if (e.target.value.length <= CHARACTER_LIMIT) {
      setFeedback(e.target.value);
    }
  };

  // Handle change for EastBadge Feedback field with character limit
  const handleEastBadgeFeedbackChange = (e) => {
    if (e.target.value.length <= CHARACTER_LIMIT) {
      setEastBadgeFeedback(e.target.value);
    }
  };

  // Handle change for Technical Issues field with character limit
  const handleTechnicalIssueDetailsChange = (e) => {
    if (e.target.value.length <= CHARACTER_LIMIT) {
      setTechnicalIssueDetails(e.target.value);
    }
  };

  // Handler for form submission
  const handleSubmit = async () => {
    // Validate required fields (Yes/No and Rating fields)
    if (!sessionRating || !overallExperienceRating || expertOnTime === '' || technicalIssues === '') {
      alert('Please answer all required fields.');
      return;
    }

    // Prepare feedback data
    const feedbackData = {
      rating: sessionRating,
      overall_experience_rating: overallExperienceRating,
      feedback,
      expert_on_time: expertOnTime === 'Yes',
      technical_issues: technicalIssues === 'Yes',
      technical_issue_details: technicalIssueDetails,
      east_badge_feedback: eastBadgeFeedback, // Optional field
    };

    // Set submitting state and call onSubmit with feedback data
    setSubmitting(true);
    try {
      await onSubmit(feedbackData);
      onClose(); // Close the modal on successful submission
    } catch (error) {
      console.error('Error submitting feedback:', error);
      alert('Failed to submit feedback. Please try again.');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      sx={{
        display: 'flex',
        alignItems: 'center', // Centers the modal vertically
        justifyContent: 'center', // Centers the modal horizontally
      }}
    >
      <Box
        sx={{
          position: 'relative',
          width: { xs: 300, sm: 500, md: 600 }, // Responsive width
          maxHeight: '85vh', // Restricts height to 80% of viewport height
          overflowY: 'auto', // Adds scroll if content overflows
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 24,
          p: 6,
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
        }}
      >
        {/* Modal Header */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 0 }}>
          <Typography id="modal-title" variant="h6" component="h2" sx={{ color: 'black' }}>
            Session Feedback
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        {/* Feedback Form */}
        <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 0 }}>
          {/* Session Rating */}
          <Box sx={{ mb: 3 }}>
            <Typography component="legend" sx={{ color: 'black' }}>
              How would you rate your session with the expert?
            </Typography>
            <Rating
              name="session-rating"
              value={sessionRating}
              onChange={(event, newValue) => setSessionRating(newValue)}
            />
          </Box>

          {/* Review Text */}
          <TextField
            label="Please share your review of the expert (Optional, but it will help other users)"
            multiline
            rows={3}
            fullWidth
            variant="outlined"
            value={feedback}
            onChange={handleFeedbackChange}
            placeholder="Your review will be visible on the expert's profile."
            sx={{ mb: 3 }}
            helperText={`${feedback.length}/${CHARACTER_LIMIT} characters`}
          />

          {/* Expert On Time */}
          <FormControl component="fieldset" sx={{ mb: 3 }}>
            <FormLabel component="legend" sx={{ color: 'black' }}>
              Did the expert join the session on time?
            </FormLabel>
            <RadioGroup
              name="expert-on-time"
              value={expertOnTime}
              onChange={(e) => setExpertOnTime(e.target.value)}
              row
            >
              <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="No" control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>

          {/* Technical Issues */}
          <FormControl component="fieldset" sx={{ mb: 3 }}>
            <FormLabel component="legend" sx={{ color: 'black' }}>
              Were there any technical issues connecting or starting the session?
            </FormLabel>
            <RadioGroup
              name="technical-issues"
              value={technicalIssues}
              onChange={(e) => setTechnicalIssues(e.target.value)}
              row
            >
              <FormControlLabel value="No" control={<Radio />} label="No" />
              <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
            </RadioGroup>
          </FormControl>

          {/* Technical Issue Details (if applicable) */}
          {technicalIssues === 'Yes' && (
            <TextField
              label="Please describe the issues you encountered."
              multiline
              rows={3}
              fullWidth
              variant="outlined"
              value={technicalIssueDetails}
              onChange={handleTechnicalIssueDetailsChange}
              sx={{ mb: 3 }}
              helperText={`${technicalIssueDetails.length}/${CHARACTER_LIMIT} characters`}
            />
          )}

          {/* Overall Experience Rating */}
          <Box sx={{ mb: 3 }}>
            <Typography component="legend" sx={{ color: 'black' }}>
              How would you rate your overall experience on EastBadge?
            </Typography>
            <Rating
              name="overall-experience-rating"
              value={overallExperienceRating}
              onChange={(event, newValue) => setOverallExperienceRating(newValue)}
            />
          </Box>

          {/* EastBadge Feedback (Optional) */}
          <TextField
            label="Do you have any feedback for EastBadge? (Optional)"
            multiline
            rows={3}
            fullWidth
            variant="outlined"
            value={eastBadgeFeedback}
            onChange={handleEastBadgeFeedbackChange}
            placeholder="Your feedback will help us prioritize deliveries and inform our roadmap."
            sx={{ mb: 3 }}
            helperText={`${eastBadgeFeedback.length}/${CHARACTER_LIMIT} characters`}
          />

          {/* Submit Button */}
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={isSubmitDisabled || submitting}
            sx={{
              mt: 2,
              width: '100%',
              textTransform: 'capitalize',
              bgcolor: isSubmitDisabled ? '#bdbdbd' : 'primary.main',
              color: isSubmitDisabled ? '#9e9e9e' : '#ffffff',
              '&:hover': {
                bgcolor: isSubmitDisabled ? '#bdbdbd' : 'primary.dark',
              },
            }}
          >
            {submitting ? <CircularProgress size={24} /> : 'Submit Feedback'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

// Prop types for component props
UserSessionFeedbackSubmission.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  session: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  csrfToken: PropTypes.string.isRequired,
};

export default UserSessionFeedbackSubmission;
